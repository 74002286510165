// src/CookieBanner.jsx
import React, { useEffect } from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { initGA, logPageView } from '../analytics';

const CookieBanner = () => {
  useEffect(() => {
    const consent = Cookies.get('userConsent');
    if (consent === 'true') {
      initGA('G-PJVWSY4BT0'); // Replace with your Measurement ID
      logPageView();
    }
  }, []);

  const handleAccept = () => {
    initGA('G-PJVWSY4BT0'); // Replace with your Measurement ID
    logPageView();
  };

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      enableDeclineButton
      onAccept={handleAccept}
      onDecline={() => {
        console.log('Cookies declined');
      }}
      cookieName="userConsent"
      style={{ background: '#2c47ff', color: 'white' }}
      buttonStyle={{ background: '#e42159', color: 'white', fontSize: '13px' }}
      declineButtonStyle={{ background: '#f97025', color: 'white', fontSize: '13px' }}
      expires={150}
    >
      We use cookies to enhance your experience. By continuing, you agree to our use of cookies.
    </CookieConsent>
  );
};

export default CookieBanner;
