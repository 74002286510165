// src/store.js
import { createStore } from 'redux';

// Define your initial state
const initialState = {
  user: {
    isLoggedIn: false,
    id: null,
    // other user properties
  },
  // other state properties
};

// Create a reducer function
function rootReducer(state = initialState, action) {
  switch (action.type) {
    // Define how your state should change based on actions
    default:
      return state;
  }
}

// Create the Redux store
const store = createStore(
  rootReducer,
  // Enable Redux DevTools Extension if available
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
