
import React, { useEffect, useState } from 'react';
import { Facebook, Instagram, Linkedin, Mail, Phone, MapPin } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const Footer = () => {
  const { t, i18n } = useTranslation();
  const currentYear = new Date().getFullYear();

  // State management
  const [language, setLanguage] = useState(i18n.language || 'en');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Language toggle function
  const toggleLanguage = () => {
    const newLang = language === 'en' ? 'fr' : 'en';
    i18n.changeLanguage(newLang);
    setLanguage(newLang);
    localStorage.setItem('language', newLang);
  };

  // Newsletter subscription handler
  const handleSubscribe = async (e) => {
    e.preventDefault();
    
    // Basic Frontend Validation
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError(t('footer.errors.invalidEmail') || 'Please enter a valid email address.');
      return;
    }

    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const response = await axios.post(`https://queel.onrender.com/api/subscriptions/subscribe`, { email });
      
      if (response.status === 201) {
        setSuccess(response.data.message || t('footer.success.subscription'));
        setEmail('');
      } else {
        setError(response.data.message || t('footer.errors.generic'));
      }
    } catch (err) {
      if (err.response) {
        // Server responded with a status other than 2xx
        if (err.response.data.errors) {
          setError(err.response.data.errors.map((err) => err.msg).join(', '));
        } else if (err.response.data.message) {
          setError(err.response.data.message);
        } else {
          setError(t('footer.errors.generic'));
        }
      } else if (err.request) {
        // Request was made but no response received
        setError(t('footer.errors.network'));
      } else {
        // Something happened in setting up the request
        setError(t('footer.errors.subscription'));
      }
    } finally {
      setLoading(false);
    }
  };

  // Set initial language from localStorage
  useEffect(() => {
    const savedLang = localStorage.getItem('language');
    if (savedLang && savedLang !== i18n.language) {
      i18n.changeLanguage(savedLang);
      setLanguage(savedLang);
    }
  }, [i18n]);

  return (
    <footer className="bg-gradient-to-r from-gray-900 to-gray-800 text-gray-300">
      <div className="container mx-auto px-4 pt-12 pb-8">
        {/* Top Section with Logo and Newsletter */}
        <div className="mb-12 text-center">
          <h2 className="text-3xl font-bold text-white mb-4">Queel</h2>
          <p className="text-gray-400 mb-6 max-w-xl mx-auto">
            {t('footer.aboutQueelDescription')}
          </p>
          <form onSubmit={handleSubscribe} className="flex flex-col items-center max-w-md mx-auto">
            <div className="flex w-full gap-2">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t('footer.newsletterPlaceholder')}
                className="flex-1 px-4 py-2 rounded-lg bg-gray-800 border border-gray-700 focus:outline-none focus:border-blue-500"
                disabled={loading}
              />
              <button 
                type="submit" 
                className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={loading}
              >
                {loading ? t('footer.loading') : t('footer.subscribe')}
              </button>
            </div>
            {error && (
              <p className="mt-2 text-red-400 text-sm">{error}</p>
            )}
            {success && (
              <p className="mt-2 text-green-400 text-sm">{success}</p>
            )}
          </form>
        </div>

        {/* Rest of the footer content remains the same */}
        {/* Main Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 mb-12">
          {/* About Section */}
          <div>
            <h4 className="text-white font-semibold text-lg mb-4">{t('footer.aboutQueel')}</h4>
            <p className="text-gray-400 mb-4">
              {t('footer.aboutQueelDescription')}
            </p>
            <div className="space-y-2">
              <div className="flex items-center gap-2">
                <Mail size={16} className="text-blue-400" />
                <a href="mailto:contact@queel.co" className="hover:text-white transition duration-200">
                  contact@queel.co
                </a>
              </div>
              <div className="flex items-center gap-2">
                <Phone size={16} className="text-blue-400" />
                <a href="tel:+1234567890" className="hover:text-white transition duration-200">
                  +(33)6156622305
                </a>
              </div>
              <div className="flex items-center gap-2">
                <MapPin size={16} className="text-blue-400" />
                <span>{t('footer.location')}</span>
              </div>
            </div>
          </div>

          {/* Company Links */}
          <div>
            <h4 className="text-white font-semibold text-lg mb-4">{t('footer.company')}</h4>
            <ul className="space-y-3">
              {t('footer.companyLinks', { returnObjects: true }).map((item) => (
                <li key={item}>
                  <a
                    href={`/${item.toLowerCase().replace(/\s+/g, '-')}`}
                    className="hover:text-white transition duration-200 flex items-center gap-2"
                  >
                    <span className="h-1 w-1 bg-blue-400 rounded-full"></span>
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Support Section */}
          <div>
            <h4 className="text-white font-semibold text-lg mb-4">{t('footer.support')}</h4>
            <ul className="space-y-3">
              {t('footer.supportLinks', { returnObjects: true }).map((item) => (
                <li key={item}>
                  <a
                    href={`/${item.toLowerCase().replace(/\s+/g, '-')}`}
                    className="hover:text-white transition duration-200 flex items-center gap-2"
                  >
                    <span className="h-1 w-1 bg-blue-400 rounded-full"></span>
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Social Media and Language Toggle */}
          <div>
            <h4 className="text-white font-semibold text-lg mb-4">{t('footer.connectWithUs')}</h4>
            <div className="grid grid-cols-3 gap-4 mb-6">
              <a
                href="https://www.facebook.com/queelco/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center h-12 bg-gray-800 rounded-lg hover:bg-gray-700 transition duration-200"
                aria-label={t('footer.socialMedia.facebook')}
              >
                <Facebook className="text-blue-400" />
              </a>
              <a
                href="https://www.instagram.com/queelco/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center h-12 bg-gray-800 rounded-lg hover:bg-gray-700 transition duration-200"
                aria-label={t('footer.socialMedia.instagram')}
              >
                <Instagram className="text-blue-400" />
              </a>
              <a
                href="https://www.linkedin.com/company/queelco/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center h-12 bg-gray-800 rounded-lg hover:bg-gray-700 transition duration-200"
                aria-label={t('footer.socialMedia.linkedin')}
              >
                <Linkedin className="text-blue-400" />
              </a>
            </div>

            {/* Language Toggle Button */}
            <button
              onClick={toggleLanguage}
              className="mt-4 bg-white text-gray-800 px-4 py-2 rounded-full font-semibold hover:bg-gray-100 transition duration-200"
              aria-label={
                language === 'en' ? 'Switch to French' : 'Passer en anglais'
              }
            >
              {language === 'en' ? 'Français' : 'English'}
            </button>
          </div>
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="border-t border-gray-800">
        <div className="container mx-auto px-4 py-6">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-sm text-gray-400">
              {t('footer.bottomBar.rightsReserved', { year: currentYear })}
            </p>
            <div className="flex space-x-6 mt-4 md:mt-0">
              <a href="/sitemap" className="text-sm text-gray-400 hover:text-white transition duration-200">
                {t('footer.bottomBar.sitemap')}
              </a>
              <a href="/accessibility" className="text-sm text-gray-400 hover:text-white transition duration-200">
                {t('footer.bottomBar.accessibility')}
              </a>
              <a href="/legal" className="text-sm text-gray-400 hover:text-white transition duration-200">
                {t('footer.bottomBar.legal')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
    
  );
};

export default Footer;