// src/context/ConversationContext.jsx
import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ConversationContext = createContext();

export const ConversationProvider = ({ children }) => {
  const [selectedConversation, setSelectedConversation] = useState(null);

  const handleConversationSelect = (conversation) => {
    setSelectedConversation(conversation);
  };

  return (
    <ConversationContext.Provider value={{ selectedConversation, handleConversationSelect }}>
      {children}
    </ConversationContext.Provider>
  );
};

ConversationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
