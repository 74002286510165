import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu, X, Globe } from 'lucide-react';

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  const toggleLanguage = () => {
    const newLang = i18n.language === 'en' ? 'fr' : 'en';
    i18n.changeLanguage(newLang);
    localStorage.setItem('language', newLang);
  };

  const NavLink = ({ to, children }) => {
    const isActive = location.pathname === to;
    return (
      <Link to={to} className="relative group">
        <span
          className={`text-sm font-medium transition-colors duration-200
            ${scrolled 
              ? isActive ? 'text-black dark:text-white' : 'text-gray-600 dark:text-gray-300'
              : isActive ? 'text-white' : 'text-gray-100 hover:text-white'
            }
          `}
        >
          {children}
        </span>
        <span
          className={`absolute -bottom-1 left-0 w-full h-0.5 transform origin-left transition-transform duration-200 ease-out
            ${scrolled ? 'bg-black dark:bg-white' : 'bg-white'}
            ${isActive ? 'scale-x-100' : 'scale-x-0 group-hover:scale-x-100'}
          `}
        />
      </Link>
    );
  };

  return (
    <div className="fixed top-0 left-0 w-full z-50">
      <nav className={`transition-all duration-300 ${scrolled ? 'py-4' : 'py-6'}`}>
        <div
          className={`mx-auto px-6 max-w-7xl transition-all duration-300 rounded-2xl
            ${scrolled ? 'bg-white/80 dark:bg-black/80 shadow-sm backdrop-blur-sm' : 'bg-transparent'}
          `}
        >
          <div className="flex items-center justify-between h-16">
            {/* Logo */}
            <Link to="/" className="flex items-center space-x-2">
              <span className={`text-2xl font-semibold transition-colors duration-200
                ${scrolled 
                  ? 'bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent'
                  : 'text-white'
                }`}>
                Queel
              </span>
            </Link>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-8">
              <NavLink to="/candidates">{t('nav.candidates')}</NavLink>
              <NavLink to="/companies">{t('nav.companies')}</NavLink>
              <NavLink to="/about">{t('nav.about')}</NavLink>
              <NavLink to="/contact">{t('nav.contact')}</NavLink>
              <NavLink to="/job-postings">{t('nav.jobs')}</NavLink>

              {/* Language Toggle */}
              <button
                onClick={toggleLanguage}
                className={`flex items-center space-x-2 px-4 py-2 text-sm font-medium
                  rounded-xl transition-all duration-200
                  ${scrolled 
                    ? 'bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 text-gray-900 dark:text-gray-100'
                    : 'bg-white/10 hover:bg-white/20 text-white'}
                `}
                aria-label={i18n.language === 'en' ? 'Switch to French' : 'Passer en anglais'}
              >
                <Globe className="w-4 h-4" />
                <span>{i18n.language === 'en' ? 'FR' : 'EN'}</span>
              </button>
            </div>

            {/* Mobile Menu Button */}
            <button
              onClick={() => setIsOpen(!isOpen)}
              className={`md:hidden rounded-xl p-2 transition-colors duration-200
                ${scrolled 
                  ? 'hover:bg-gray-100 dark:hover:bg-gray-800 text-gray-900 dark:text-gray-100'
                  : 'hover:bg-white/10 text-white'}
              `}
              aria-label="Toggle menu"
              aria-expanded={isOpen}
              aria-haspopup="menu"
            >
              {isOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
            </button>
          </div>

          {/* Mobile Menu */}
          <div
            className={`md:hidden overflow-hidden transition-all duration-300 ease-out
              ${isOpen ? 'max-h-96 opacity-100 pb-4' : 'max-h-0 opacity-0'}
            `}
          >
            <div className="flex flex-col space-y-4 px-2">
              {[
                { to: '/candidates', label: t('nav.candidates') },
                { to: '/companies', label: t('nav.companies') },
                { to: '/about', label: t('nav.about') },
                { to: '/contact', label: t('nav.contact') },
                {to : '/job-postings', label: t('nav.jobs') },
              ].map((item) => (
                <Link
                  key={item.to}
                  to={item.to}
                  className={`px-4 py-2 text-sm font-medium rounded-xl transition-colors duration-200
                    ${scrolled
                      ? location.pathname === item.to
                        ? 'bg-gray-100 dark:bg-gray-800 text-black dark:text-white'
                        : 'text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800/50'
                      : location.pathname === item.to
                        ? 'bg-white/10 text-white'
                        : 'text-gray-100 hover:bg-white/10 hover:text-white'
                    }`}
                >
                  {item.label}
                </Link>
              ))}

              {/* Mobile Language Toggle */}
              <button
                onClick={toggleLanguage}
                className={`flex items-center justify-between px-4 py-2 text-sm font-medium
                  rounded-xl transition-colors duration-200
                  ${scrolled 
                    ? 'bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 text-gray-900 dark:text-gray-100'
                    : 'bg-white/10 hover:bg-white/20 text-white'}
                `}
              >
                <span className="flex items-center space-x-2">
                  <Globe className="w-4 h-4" />
                  <span>{i18n.language === 'en' ? 'Switch to French' : 'Passer en anglais'}</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;