// src/App.js
import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ErrorBoundary from './components/ErrorBoundary';
import { ConversationProvider } from './context/ConversationContext';
import CookieBanner from './components/CookieBanner'; // Import CookieBanner
import { logPageView } from './analytics'; // Import logPageView

// Lazy load pages
const Home = lazy(() => import('./pages/Home'));
const ForCandidates = lazy(() => import('./pages/ForCandidates'));
const ForCompanies = lazy(() => import('./pages/ForCompanies'));
const About = lazy(() => import('./pages/About'));
const Contact = lazy(() => import('./pages/Contact'));
const JobPostings = lazy(() => import('./pages/JobPostings')); // Import JobPostings
const Application = lazy(() => import('./pages/Application')); // Import Application

// Favicon management component
const FaviconManager = () => {
  const location = useLocation();

  useEffect(() => {
    const setFavicon = (href) => {
      const favicon = document.querySelector("link[rel='icon']");
      if (favicon) {
        favicon.href = href;
      } else {
        const newFavicon = document.createElement('link');
        newFavicon.rel = 'icon';
        newFavicon.href = href;
        document.head.appendChild(newFavicon);
      }
    };

    // Define favicon paths for different routes
    const faviconMap = {
      '/': '/favicon.ico', // Default favicon
      '/candidates': '/favicon-candidates.ico',
      '/companies': '/favicon-companies.ico',
      '/about': '/favicon-about.ico',
      '/contact': '/favicon-contact.ico',
      '/job-postings': '/favicon-jobpostings.ico',
      '/job-postings/apply/:id': '/favicon-apply.ico',
    };

    // Extract base path without parameters
    const basePath = location.pathname.split('/apply')[0];
    const faviconPath = faviconMap[basePath] || '/favicon.ico';
    setFavicon(faviconPath);
  }, [location]);

  return null;
};

// AppContent component to access location and track page views
const AppContent = () => {
  const location = useLocation();
  const showFooter = location.pathname !== '/about';

  useEffect(() => {
    logPageView();
  }, [location]);

  return (
    <>
      <FaviconManager />
      <Navbar />
      <ErrorBoundary>
        <AnimatePresence mode="wait">
          <Suspense
            fallback={
              <div className="flex items-center justify-center min-h-screen">
                Loading Page...
              </div>
            }
          >
            <Routes location={location} key={location.pathname}>
              <Route path="/" element={<Home />} />
              <Route path="/candidates" element={<ForCandidates />} />
              <Route path="/companies" element={<ForCompanies />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/job-postings" element={<JobPostings />} />
              <Route path="/job-postings/apply/:id" element={<Application />} />
              {/* Add a 404 Not Found Route */}
              <Route path="*" element={<Home />} />
            </Routes>
          </Suspense>
        </AnimatePresence>
      </ErrorBoundary>
      {showFooter && <Footer />}
    </>
  );
};

const App = () => {
  return (
    <Router>
      <ConversationProvider>
        <AppContent />
        <CookieBanner /> {/* Include CookieBanner here */}
      </ConversationProvider>
    </Router>
  );
};

export default App;
